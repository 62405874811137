import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 그룹 관리 화면 관련 기능 클래스
 */
class BoardGroup {

    /**
     * 생성자
     */
    constructor() {
        this.useYn = {UseYn: 'Y'}
    }

    /**
     * // 그룹관리 목록 조회 
     * @returns 
     */
    getAuthorityGroupInfo() {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url)
            const request = new FoxBizRequest()
            request.classId = 'Site.Training.Biz.AuthorityGroupBiz'
            request.methodId = 'GetAuthorityGroupInfo'
            request.parameters = this.useYn
            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows

                if (!!rows.length) resolve(rows)
                else resolve(null)

            }).catch(error => reject(error))
        })
    }

    /**
     * // 그룹관리 그룹권한 조회 
     * @param {String} useYn
     * @returns 
     */
     getAuthorityGroupOption() {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url)
            const request = new FoxBizRequest()
            request.classId = 'Site.Training.Biz.AuthorityGroupBiz'
            request.methodId = 'SelectAuthorityGroupOption'
            request.parameters = this.useYn
            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows

                if (!!rows.length) resolve(rows)
                else resolve(null)

            }).catch(error => reject(error))
        })
    }
    
    /**
     * 그룹 저장
     * @param {Object} authorityGroupInfo 
     * @returns 
     */
     saveAuthorityGroupInfo(authorityGroupInfo) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.AuthorityGroupBiz';
            request.methodId = 'InsertAuthorityGroupInfo';

            request.parameters = authorityGroupInfo;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }
}

export const boardgroup = new BoardGroup();