<template>
    <div class="admin-content">
		<h3>그룹 관리</h3>
		<div class="flex-wrap right">
			<div class="ml10">
				<div class="btn-wrap">
                    <button type="button" class="btn-box green"
                        @click="{
                            state.dialog.isOpen=true;
                            state.dialog.isNew=true;
                        }">그룹 추가</button>
				</div>
			</div>
		</div>

		<!-- 총 개수 -->
		<div class="total-box">
			<p>Total <strong>{{state.dataTable.boardGroupItems === null ? 0 : state.dataTable.boardGroupItems.length}}</strong></p>
		</div>

		<DataTable class="p-datatable-sm"
            columnResizeMode="fit"
            scrollHeight="497px"
            :value="state.dataTable.boardGroupItems"
            :paginator="true"
            :rowHover="true"
            :rows="15"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            @row-click="eventHandler.dataTable.onRowClick"
            :resizableColumns="true">

            <template #empty>
                데이터가 없습니다.
            </template>

            <Column field="CodeVal" header="그룹 ID" :sortable="true" style="width:200px;" headerClass="tc"></Column>
            <Column field="CodeName" header="그룹명" :sortable="true" style="width:200px;" headerClass="tc"></Column>
            <Column field="UseYn" header="사용여부" :sortable="true" style="text-align:center; width:100px;" headerClass="tc"></Column>

        </DataTable>

		<Dialog 
            :header="state.dialog.header"
            :visible="state.dialog.isOpen"
            :modal="true"
            @show="eventHandler.dialog.show"
            @hide="eventHandler.dialog.hide"
            @close="state.dialog.isOpen=false"
            @update:visible="state.dialog.isOpen = false">
            
            <div class="dialog-wrap1">
                <div>
                    <label>그룹ID</label>
                    <div>
                        <input class="input-text mr10" type="text" v-model.trim="state.dataTable.rowDataModel.CodeVal" disabled />
                    </div>
                </div>
                <div>
                    <label>그룹명 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" type="text" ref="codeName" v-model.trim="state.dataTable.rowDataModel.CodeName" maxlength="128"/>
                    </div>
                </div>
                <div>
                    <label>그룹권한 <i class="required">*</i></label>
                    <div class="select-box">
                        <select v-model="state.dataTable.selectedCode" ref="groupName">
                            <option v-for="item in state.dropDown.items" v-bind:value="item.CodeVal" v-bind:key="item" v-text="item.CodeName" />
                        </select>
                    </div>
                </div>
                <div>
                    <label>사용여부 <i class="required">*</i></label>
                    <div class="select-box">
                        <select v-model="state.dataTable.rowDataModel.UseYn" ref="useYn">
                            <option value="Y">Y</option>
                            <option value="N">N</option>
                        </select>
                    </div>
                </div>
            </div>
            <br>
            <div class="flex-wrap right">
                <div class="ml10">
                    <div class="btn-wrap">
                        <button class="btn-box gray" @click="methods.boardGroupValidation">생성</button>
                    </div>
                </div>
            </div>
        </Dialog>
	</div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { boardgroup } from '@/modules/site-training/board-group-index.js';
import { useStore } from 'vuex';
import alertManager from '@/utils/alert-confirm-manager';
export default {
components: {
},
setup() {

    // 스토어
    const store = useStore();
    
    // 유효성 검사 ref
    const codeName = ref(); // 그룹명
    const groupName = ref(); // 그룹권한
    const useYn = ref(); // 사용여부

    // state
    const state = reactive({
        // 데이터 테이블 state
        dataTable: {

            // 그룹 목록
            boardGroupItems: null,

            // 선택된 시스템코드
		    selectedCode: '1',

            // 행 데이터 모델 임시계정 저장 수정시 사용
            rowDataModel: new Object(),

        },

        dialog: {

            // 다이얼로그 헤더 텍스트
            header: '그룹관리',

            // 다이얼로그 오픈여부
            isOpen: false,

            // 신규 여부
            isNew: false,

            InsertFlag: '',

        },

        dropDown: {

            items: new Array(),

        },
    });

    // 메서드
    const methods = {

        // 그룹관리 목록 조회
        getBoardGroupInfo: () => {

            // 스토어 값 변경
            store.commit("setLoadingStatus", true);

            boardgroup.getAuthorityGroupInfo().then(result => {

                state.dataTable.boardGroupItems = result;

            }).finally(() => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", false);
            });
        },

        // 그룹 저장
        saveAuthorityGroupInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);
                
                boardgroup.saveAuthorityGroupInfo(state.dataTable.rowDataModel)
                
                .then((response) => {

                    if(response.data.success) {
        
                        alertManager.toast.save();

                        // 목록 재조회
                        methods.getBoardGroupInfo();

                    }

                })
                    
                .finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                    
                    // 팝업 닫기
                    state.dialog.isOpen = false;

                });
                
            },
            // 그룹 정보 유효성검사
            boardGroupValidation: () => {

                let validator = state.dataTable.rowDataModel;

                 //그룹권한 선택값 전달
                 state.dataTable.rowDataModel.RuleCode = state.dataTable.selectedCode;

                // 그룹명
                if (!validator.CodeName) {

                    codeName.value.focus();
                    alertManager.alert.warning('그룹명을 입력해주세요');
                }
                // 그룹권한
                else if (!validator.RuleCode) {

                    groupName.value.focus();
                    alertManager.alert.warning('그룹권한을 선택해주세요');
                }
                // 사용여부
                else if (!validator.UseYn) {

                    useYn.value.focus();
                    alertManager.alert.warning('사용여부를 선택해주세요');
                }
                else {

                    // 그룹 상세 저장
                   alertManager.confirm.save().then(() => { methods.saveAuthorityGroupInfo() });
                }
            },

    }

    // 이벤트 핸들러
    const eventHandler = {

        // 데이터 테이블 관련 이벤트 핸들러
        dataTable: {
            
            // 행 클릭 이벤트 처리
            onRowClick: event => {

                // 행 데이터 모델 설정
                state.dataTable.rowDataModel = Object.assign({}, event.data);
                
                //그룹 권한 설정
                state.dataTable.selectedCode = event.data.RuleCode;

                // 팝업 오픈
                state.dialog.isOpen = true;

                //저장시 저장 수정 체크 
                state.dialog.InsertFlag = 'Update';
            },
        },

        // 다이얼로그 관련 이벤트 핸들러
        dialog: {

            // show 이벤트 처리
            show: () => {
                
                // 신규 다이얼로그 모드
                if(state.dialog.isNew) {
                    state.dataTable.selectedCode = '1';
                    state.dataTable.rowDataModel.UseYn = 'Y';
                    state.dialog.InsertFlag = 'New';
                    state.dataTable.rowDataModel.CodeVal = '';
                }

                // 사용자ID
                state.dataTable.rowDataModel.UserNo = store.getters.getUserId;
                
                //저장, 수정여부 전달
                state.dataTable.rowDataModel.InsertType = state.dialog.InsertFlag;
            },

            // hide 이벤트 처리
            hide: () => {

                // 행 데이터모델 초기화
                state.dataTable.rowDataModel = new Object();
                state.dialog.isNew = false;
                state.dialog.InsertFlag = '';

            },
        },
    }

    // Dom 객체 그려진 이후
    onMounted(() => {

        // 그룹관리 초기조회
        methods.getBoardGroupInfo();

        //그룹관리 그룹권한 조회 
        boardgroup.getAuthorityGroupOption().then(result => {

                state.dropDown.items = result;

         });

    });

    return {
        state,
        boardgroup,
        methods,
        eventHandler,
        codeName,
        groupName,
        useYn,
    }
}
}
</script>